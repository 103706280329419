import type { Seo } from '~/models/seo';
import type { Resolver } from '~/models/resolver';

export const useMeta = () => {
	const { meta } = useRoute();
	const { title, header, keywords, description } = (meta.seo || {
		title: null,
		header: null,
		keywords: null,
		description: null,
	}) as Seo.Model;

	useHead({
		title,
		titleTemplate: '%s',
		meta: [
			{ name: 'keywords', content: keywords?.length ? keywords.join(', ') : null },
			{ name: 'description', content: description },
		],
	});

	const isSubPage = computed(() => Boolean(meta.isSubPage));

	const alias = computed(() => String(meta.alias));

	const collectionItem = computed(
		() => meta.collectionItem as Resolver.collectionItem | undefined
	);

	return {
		title,
		header,
		keywords,
		description,

		isSubPage,
		alias,
		collectionItem,
	};
};
